<template>
  <div>
    <b-form-timepicker v-model="internalVal" hide-header no-close-button :state="state" :placeholder="placeholder" />
  </div>
</template>

<script>
export default {
  components: {
  },
  props: ['value', 'state', 'placeholder'],
  data () {
    return {
      internalVal: '12:00:00',
    };
  },
  watch: {
    internalVal () {
      this.emit();
    },
    value (val) {
      this.checkValue(val);
    },
  },
  mounted () {
    if (this.value) {
      this.checkValue(this.value);
    }
  },
  methods: {
    checkValue (val) {
      if (!val) {
        return;
      }
      const [ HH, mm ] = val.split(':');
      this.internalVal = `${HH}:${mm}:00`;
    },
    emit () {
      if (!this.internalVal) {
        return;
      }
      const [ HH, mm ] = this.internalVal.split(':');
      this.$emit('input', `${HH}:${mm}`);
    },
  },
};
</script>
