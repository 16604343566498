<template>
  <b-row>
    <b-col>
      <b-card title="Metering data">
        <blueprint-data :where="where" api-route-path="data/metering" :new-default-values="newDefaultValues" :fields="fields" no-edit>
          <template v-slot:search>
            <b-form-group label="Utility Meter Type" label-for="typeId">
              <blueprint-search-input id="typeId" :key="typeId" v-model="typeId" placeholder="Type to search" :api-route-path="`inventory/utilitymetertypes`" />
            </b-form-group>
            <b-form-group label="Property" label-for="propertyId">
              <blueprint-search-input id="propertyId" :key="propertyId" v-model="propertyId" placeholder="Type to search" :api-route-path="`inventory/properties`" />
            </b-form-group>
            <b-form-group v-if="propertyId" label="Unit" label-for="unitId">
              <blueprint-search-input id="unitId" :key="unitId" v-model="unitId" placeholder="Type to search" :where="{ propertyId }" :api-route-path="`inventory/units`" />
            </b-form-group>
            <b-form-group v-if="unitId" label="Utility meter" label-for="meterId">
              <blueprint-search-input id="meterId" :key="meterId" v-model="meterId" placeholder="Type to search" :api-route-path="`inventory/units/${unitId}/utilitymeters`" />
            </b-form-group>
            <b-form-group label="Date" label-for="date">
              <DatePicker id="date" v-model="date" allow-null reset-button type="text" />
            </b-form-group>
          </template>

          <template v-slot:date="{ item }">
            {{ item.date | moment('lll') }}
          </template>
          <template v-slot:meter="{ item }">
            {{ item.meter.name }} ({{ item.meter.type.name }})
          </template>
          <template v-slot:value="{ item }">
            {{ item.value }} {{ item.meter.type.unit }}
          </template>
          <template v-slot:unit="{ item }">
            {{ item.meter.unit.property.name }} / {{ item.meter.unit.name }}
          </template>
          <template v-slot:manual="{ item }">
            <b-badge v-if="item.manual===true" variant="success">
              Manual
            </b-badge>
            <b-badge v-if="item.manual!==true" variant="info">
              Automatic
            </b-badge>
          </template>
          <template v-slot:default="{ state, item }">
            <b-form-group label="Property" label-for="propertyId">
              <blueprint-search-input
                id="propertyId"
                :key="propertyId"
                v-model="propertyId"
                placeholder="Type to search"
                :api-route-path="`inventory/properties`"
              />
            </b-form-group>
            <b-form-group v-if="propertyId" label="Unit" label-for="unitId">
              <blueprint-search-input
                id="unitId"
                :key="unitId"
                v-model="unitId"
                placeholder="Type to search"
                :where="{ propertyId }"
                :api-route-path="`inventory/units`"
              />
            </b-form-group>
            <b-form-group v-if="unitId" label="Utility meter" label-for="meterId">
              <blueprint-search-input
                id="meterId"
                :key="item.meterId"
                v-model="item.meterId"
                placeholder="Type to search"
                :api-route-path="`inventory/units/${unitId}/utilitymeters`"
              />
            </b-form-group>
            <b-form-group label="Date" label-for="date">
              <TimeDatePicker id="date" v-model="item.date" :state="state('date')" type="text" />
            </b-form-group>
            <b-form-group label="Value" label-for="value">
              <b-form-input id="value" v-model="item.value" :state="state('value')" type="text" placeholder="value" />
            </b-form-group>
          </template>
        </blueprint-data>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import TimeDatePicker from '../../../components/time-date-picker';
import DatePicker from '../../../components/date-picker';
import * as moment from 'moment';

export default {
  components: {
    TimeDatePicker,
    DatePicker
  },
  data () {
    return {
      newDefaultValues: {
        date: new Date(),
      },
      unitId: undefined,
      propertyId: undefined,
      typeId: undefined,
      meterId: undefined,
      date: undefined,
      where: {},
      fields: [
        {
          key: 'unit',
          label: 'Unit',
          slot: true,
        },
        {
          key: 'meter',
          label: 'Meter',
          slot: true,
        },
        {
          key: 'date',
          sortable: true,
          slot: true,
        },
        {
          key: 'value',
          slot: true,
          sortable: false,
        },
        {
          key: 'manual',
          slot: true,
          sortable: false,
        },
      ],
    };
  },
  watch: {
    unitId () {
      this.meterId = undefined;
      this.buildWhere();
    },
    propertyId () {
      this.unitId = undefined;
      this.meterId = undefined;
      this.buildWhere();
    },
    typeId () {
      this.buildWhere();
    },
    date () {
      this.buildWhere();
    },
    meterId () {
      this.buildWhere();
    },
  },
  methods: {
    parse (name, arr) {
      const ret = {};
      if (Array.isArray(arr) && arr.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        ret[name] = arr[0];
      } else if (Array.isArray(arr) && arr.length > 1) {
        ret[name] = { $in: arr };
      } else if (arr) {
        ret[name] = arr;
      }
      return ret;
    },
    buildWhere () {
      const {
        unitId, propertyId, typeId, meterId, date
      } = this;
      const whereDate = date ? { date: { $between: [ moment(date).utc().startOf('day'), moment(date).utc().endOf('day') ] } } : {};
      this.where = {
        ...this.parse('meter.unit.propertyId', propertyId),
        ...this.parse('meter.unitId', unitId),
        ...this.parse('meter.typeId', typeId),
        ...this.parse('meter.id', meterId),
        ...whereDate
      };
    },
  },
};
</script>
