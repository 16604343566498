<template>
  <b-row>
    <b-col cols="8">
      <Datepicker v-model="internalDateVal" :state="state" :placeholder="placeholder" />
    </b-col>
    <b-col>
      <Timepicker v-model="internalTimeVal" :state="state" :placeholder="placeholder" />
    </b-col>
  </b-row>
</template>

<script>
import Timepicker from './time-picker.vue';
import Datepicker from './date-picker.vue';
import * as moment from 'moment';

export default {
  components: {
    Timepicker,
    Datepicker
  },
  props: ['value', 'state', 'placeholder'],
  data () {
    return {
      internalTimeVal: '12:00:00',
      internalDateVal: new Date(),
      internalVal: moment().set({ hours: 12, minutes: 0, seconds: 0 }),
    };
  },
  watch: {
    internalTimeVal () {
      if (this.internalTimeVal !== this.internalVal.format('HH:mm:ss')) {
        const [ hour, minute, second ] = this.internalTimeVal.split(':');
        this.internalVal.set({
          hour,
          minute,
          second
        });
        this.emit();
      }
    },
    internalDateVal () {
      const [ hour, minute, second ] = this.internalTimeVal.split(':');
      const momentDateVal = moment(this.internalDateVal).set({
        hour,
        minute,
        second
      });
      if (!momentDateVal.isSame(this.internalVal)) {
        this.internalVal = momentDateVal;
        this.emit();
      }
    },
  },
  mounted () {
    if (this.value) {
      this.checkValue(this.value);
    }
  },
  methods: {
    checkValue (val) {
      const dateMoment = moment(val);
      if (val && dateMoment.isValid() && !dateMoment.isSame(this.internalVal)) {
        this.internalVal = dateMoment;
        this.internalTimeVal = dateMoment.format('HH:mm:ss');
        this.internalDateVal = dateMoment.toDate();
        this.emit();
      }
    },
    emit () {
      this.$emit('input', this.internalVal.utc().toDate());
    }
  },
};
</script>
